.hiddenRow {
    padding: 0 !important;
}
.div-s-er
{
    background: #EEEEEE;
    display: flex;
}
.div-dl-up-f 
{
    background-color: #C82333;
}
.div-dl-up-f:hover
{
    background-color: #9B1C28;
}
.div-se-up-f:hover
{
    background: #D6D6D6 !important;
}
.div-upload
{
    height: 130px;
}
.div-gridview
{
    margin-top: 15px;
    overflow: auto;
}
.div-gridview::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}
.div-upload-file-gw>div:first-child 
{
    border-bottom: 1px solid #f0f0f0;
    background-color: #DFEBFF;
    text-align: left;
    height: 36px;
    font-size: 14px;
    color: rgba(0,0,0,.85);
    font-weight: 500;
}
.div-upload-file-gw>div:first-child>div
{
    padding: 8px;
}
.div-upl-data>div>div>div
{
    padding: 8px;
    font-size: 14px;
    color: #000000D9;
}
.div-upl-data>div>div:first-child
{
    display: flex;
    border-bottom: 1px solid #f0f0f0;
}
.div-upl-data>div>div>div:nth-child(5) svg
{
    float: right;
    font-size: 12px;
    margin-top: 6px;
}
.div-upl-data
{
    overflow: auto;
}
.div-pagination
{
    height: 50px;
    padding: 12px 0 0 0;
}
.div-pagination a {
    color: black;
    float: left;
    padding: 6px 13px;
    font-size: 14px;
    text-decoration: none;
    transition: background-color .3s;
  }
 /* thiết lập style cho class active */
  .div-pagination a.active {
    background-color: dodgerblue;
    color: white;
  }
 /* thêm màu nền khi người dùng hover vào class không active */
  .div-pagination a:hover:not(.active) {
    background-color: #ddd;
  }
.div-filter-content-upload>div:nth-child(4)>div
{
    height: 54px;
    background-color: rgba(153 , 153 , 153 , 0.64);
    border-right: solid 1px white;
}
.div-filter-find
{
    border-right: solid 1px white;
    height: 100%;
}