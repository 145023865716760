.div-footer
{
    background: #5C77D0;
    padding: 22px 50px 10px 50px;
}
.div-footer>div p
{
    margin-bottom: 8px;
}
.div-footer>div
{
    font-size: 14px;
}
.div-footer>div:first-child>p:nth-child(2)
{
    text-transform: uppercase;
}
.div-footer>div:first-child>p:not(:nth-child(2)) , .div-footer>div:last-child>p:not(:nth-child(1),:nth-child(4))
{
    font-family: 'Segoe UI Light'
}
.div-footer>div:first-child img
{
    height: 45px;
}