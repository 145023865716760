.div-share-view
{
    font-size: 15px;
    font-family: "Segoe UI";
}
.div-share-view >div >div
{
    border: solid 1px rgba(127, 127, 127, 0.54);
    padding: 0.5%;
}
.div-share-view > div:first-child
{
    border-left: solid 1px rgba(127, 127, 127, 0.54);
    border-right: solid 1px rgba(127, 127, 127, 0.54);
    background-color: #eee;
    padding: .3% 2%;
}
.div-share-view > div:first-child span
{
    font-size: 16px;
    text-transform: uppercase;
}
.div-share-view > div > div> div
{
    padding: 0.5% 2%;
}
.bdbt
{
    border-bottom: 1px solid hsla(0,0%,49.8%,.54);
}
.bdbtchild
{
    padding: 0.5% 4% !important;
    border-bottom: 1px solid hsla(0,0%,49.8%,.54);
}
.p-d-l-6
{
    padding-left: 6%;
}
.div-down-pdf
{
    text-align: right;
    padding: 2% 2% 0 0;
}
.text-nowrap {
    white-space: nowrap !important;
  }