
@keyframes loadingJ {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(80px, 0);
      background-color: gray;
      width: 60px;
    }
  }
#drop-area {
    border: 2px dashed #DADADA;
    padding: 8px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: white;
}

#drop-area.highlight {
    border: 2px dashed #DADADA;
    animation-name: blinking;
    animation-duration: 1s;
    animation-iteration-count: 100;
}
@keyframes blinking {
    50% {
        border: 2px dashed #4B86BD;
    }
  }
.div_upload_content 
{
    padding: 10px;
}
.form_UploadDicom {
    width: 100%;
    height: 100%;
}
.form_UploadDicom_Top {
    height: 10%;
    font-size: 18pt;
    color: #929A9D;
}
.form_UploadDicom_Top > div:last-child svg{
    font-size: 18px;
}
.form_UploadDicom_Top > div span
{
    font-size: 15pt;
}
.form_UploadDicom_Top > div > div , .form_UploadDicom_Top > div i
{
    padding: 0 3px 0 0 ;
}
.form_UploadDicom_Top > div:last-child > div:last-child
{
    margin: 1px 0 0 0;
}
.form_UploadDicom_Center > label {
    width: 100%;
    height: 81%;
    margin-bottom: 0 !important;
    cursor: pointer;
}
.form_UploadDicom_Bottom {
    height: 2%;
    text-align: center;
    line-height: 0.3;
}
progress {
    height: 4px;
    width: 40%;
}
#fileElem {
    display: none;
}
.form_UploadDicom_Center {
    height: 88%;
    background-image: url('../../../Images/plus.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50px;
}
.div-file-dcm
{
  height: 34px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../../Images/file-dcm.png');
}
.div-file-dcm-red
{
  height: 34px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../../Images/file-dcm-red.png');
}
.div-file-dcm-blue
{
  height: 34px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../../Images/file-dcm-blue.png');
}
.div-file-content
{
    font-size: 10px;
}
.div-collapse
{
    height: 30px;
}
.div-collapse > div 
{
    padding : 0 0 0 1.5%
}
.div-collapse svg
{
    font-size: 17px;
    margin-top: 5px;
    color: #969696;
}
.c-header-tb {
    border-bottom: 1px solid #f0f0f0;
    background-color: #F7F7F7;
    text-align: left;
    height: 36px;
    font-size: 14px;
    color: rgba(0,0,0,.85);
}
.c-header-tb tr th
{
    padding: 0 8px;
    font-weight: 500;
}
.tb-dp-data {
    border-top:none;
}
.tb-dp-data tr:hover {
    background-color: #F2F2F2;
    color: black;
}
.tb-dp-data tr {
    border-bottom: 1px solid #f0f0f0;
}
.tb-dp-data tr td {
    padding: 6px 8px;
    font-size: 14px;
    color:#000000D9;
    margin: auto;
}
.div-filter-title {
    height: 32px;
    font-size: 17px;
    line-height: 1.7;
    padding: 0 0 0 1.5%;
    border-bottom: solid 1px;
}
.div-gettested-content
{
    height: 56px;
    font-size: 15px;
    padding: 0 1%;
}
.div-filter-content {
    width: 99.9%;
    height: 54px;
    line-height: 2;
    font-size: 14px;
}
.div-filter-content>div:first-child>div:nth-child(2) 
{
    border-bottom: solid 1px #bdbdbd ;
}
.div-filter-content>div:first-child>div:nth-child(3)
{
    width: 100px;
    background-color: white;
    border: solid 1px #bdbdbd;
}
.div-filter-content>div:first-child>div:last-child
{
    padding: 0.3%;
    position: fixed;
    z-index: 1;
}
.div-lst-ma
{
    overflow: auto;
}
.div-lst-ma>div
{
    padding: 0 0 5% 0;
    border-bottom: solid 1px #bdbdbd;
}
.div-lst-a-m-child , .sp-cskcb
{
    font-size: 13px;
}
.div-lst-a-m-child:hover
{
    background-color: #1E90FF;
    color: white;
}
.ip-search-m-a
{
    margin-bottom: 5%;
    border-color: #bdbdbd !important;
}
.div-filter-content label , .div-new-a-m label , .div-file-icon
{
    font-size: 13px;
}
.div-filter-content>div
{
    line-height: 1.5;
    padding-right: 10px;
}
.div-filter-content input ,.div-filter-content select , .div-c-r-f-h input
{
    border: solid 1px;
    border-color: white white #bdbdbd white;
}
.div-filter-content select:focus-visible , .div-new-a-m select:focus-visible
{
    outline: none;
}
.div-filter-content input:focus  , .div-c-r-f-h input:focus
{
    outline: none;
}
.div-filter-content svg
{
    font-size: 14px;
    margin-top: 19px;
    color: white;
}
.div-filter-content .w-4
{
    margin-top: 3px;
}
.div-filter-fs>div
{
    height: 54px;
    background-color: rgba(153 , 153 , 153 , 0.64);
    border-right: solid 1px white;
}
.div-s-h-at
{
    width: 3.8%;
    margin-left: -0.43%;
    min-height: 20px;
    background-color: white;
    position: absolute;
    z-index: 3;
}
.div-s-h-at>div
{
    padding: 12%;
    border-bottom: solid 1px white;
    background-color: #5B96C4;
}
.div-s-h-at > div:hover
{
    background-color: #4B7B9F;
}
.div-s-h-at svg
{
    font-size: 23px;
}
.div-v-s-err
{
    margin: 10px 0 10px 0;
}
.div-v-s-err>div:first-child
{
    border-bottom: 1px solid rgba(127, 127, 127, 0.54);
    background-color: #477DB0;
    text-align: left;
    height: 36px;
    font-size: 17px;
    line-height: 2;
}
.div-v-s-err>div:first-child>div:first-child
{
    padding-left: 4%;
}
.div-v-s-data-err
{
    overflow: auto;
    border: solid 1px #B9B9B9;
}
.div-v-s-data-err>div
{
    padding: 0 1% 0 1%;
    font-size: 15px;
    border-bottom: solid 1px #B9B9B9;
}
.div-v-s-data-err>div:hover
{
    background-color: #F2F2F2;
}
.div-v-s-data-err>div>div:first-child span:last-child
{
    font-weight: 100;
}
.div-v-s-data-err>div>div:last-child>div
{
    padding: 0.5% 0;
}
.div-v-s-data-err>div>div:first-child
{
    padding: 0.5% 0 0 3%;
}
.div-s-h-upload svg 
{
    font-size: 22px;
    margin: 4px 0 0 -11px;
    color: #000000D9;
}
.load-wrapp
{
    margin-top: 0.2px;
    position: relative;
}
.bar 
{
    float: left;
    width: 15px;
    height: 3px;
    border-radius: 2px;
    background-color: black;
  }
.load-wrapp .bar 
{
    animation: loadingJ 2s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}
.div-upload
{
    margin: 0 1%;
}
.div-uploadfile , .div_refreshfile
{   
    padding: 0 10px 10px 10px;
}
.div-uploadfile:hover , .div_refreshfile:hover
{
    background: #E3E3E3;
}
.tb-n-hv>tr>td:last-child:hover
{
    background-color: white;
}
.div-icon-cdha
{
    background-image: url('../../../Images/x-quang.jpg');
}
.div-tb-pt-rs
{
    overflow: auto;
}
.div-ul-xml-title
{
    padding: 14px 0 0 0;
    margin: 0 1%;
    height: 105px;
    line-height: 1;
    border-bottom: solid 1px #bdbdbd;
}

.div-ul-xml-title p
{
    margin-bottom: 0;
}
.div-ul-xml-title p:first-child , .div-ul-xml-title>div:nth-child(2)>div:first-child
{
    font-size: 22px;
    letter-spacing: -0.03rem;
}
.div-ul-xml-title p:nth-child(2) , .div-ul-xml-title>div:first-child span
{
    font-size: 12px;
} 
.div-ul-xml-title>div:nth-child(2)>div:first-child
{
    width: 90px;
}
.div-ul-xml-title>div:nth-child(2)>div:not(:first-child) span   
{
    font-size: 13px;
    padding: 0px 3px;
}
.div-ul-xml-title>div:nth-child(2)>div:not(:first-child)
{
    padding: 0;
    line-height: 1.2;
}
.switch 
{
    position: relative;
    display: inline-block;
    width: 32px;
    height: 18px;
    margin: 2px 0 0 3px;
  }
  
.switch input { 
opacity: 0;
width: 0;
height: 0;
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #ccc;
-webkit-transition: .4s;
transition: .4s;
}

.slider:before {
position: absolute;
content: "";
height: 12px;
width: 12px;
left: 2px;
bottom: 3px;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
background-color: #2196F3;
}

input:focus + .slider {
box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
-webkit-transform: translateX(15px);
-ms-transform: translateX(15px);
transform: translateX(15px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.div-filter-key
{
    display: none;
}
.sp-filter-key-count
{
    font-weight: 600;
    color: red;
}
.div-ba-detail
{
    background-color: #E6E6E6;
}
.div-ba-detail svg
{
    color: #4C4C4C;
}
.div-ba-detail:hover
{
    background-color: #C5C5C5;
}
.a-pagination
{
    min-width:50px
}
.sp-status-ba
{
    margin-left: 5px;
}
.td-result
{
    background-color: #F7F7F7;
}
.skeletion
{
    opacity: .5;
    animation: skeletion-loading 1s linear infinite alternate;
}
.skeletion-text{
    height: .2rem;
    border-radius: .125rem;
    margin-bottom: .25rem;
}
.aaa{
    display: inline;
}
@keyframes skeletion-loading{
    0% {
        background-color: hsl(200, 20% , 80%);
    }
    100% {
        background-color: hsl(200, 20% , 95%);
    }
}