.div-rp-mac-content>div>div>div>div:first-child
{
    padding: 10px;
}
.div-rp-mac-content>div>div>div>div:first-child span
{
    color: #c00000;
    font-size: 16px;
    text-transform: uppercase;
}
.div-ba-month
{
  padding: 1%;
}
.div-ba-month:hover
{
    background-color: #c8c8c8;
}
.sp-month-chart , .sp-month-note-title
{
    color: #c00000 !important;
}
.sp-ba-day , .sp-ba-month , .sp-ba-today
{
    color: #d6c20a !important;
}
.sp-legend-number
{
    fill : #C6B00A !important;
}
.sp-legend-title
{
    fill : red;
}
.div-costbym-data, .div-costbyd-data
{
    color: #666666;
    font-size: 13px;
    border: solid 1.5px;
    padding: 13px 6px;
    border-radius: 5px;
    border-color: #DBDBDB;
    background-color: #F7F7F7;
    position: absolute;
    right: 2%;
    z-index: 1;
    cursor: all-scroll;
}
.div-costbym-data>div:nth-child(2)>div , .div-costbyd-data>div:nth-child(2)>div 
{
    padding: 18px 0;
    line-height: 0;
}
.div-costbym-data>div:nth-child(1) , .div-costbyd-data>div:nth-child(1)
{
    padding-bottom: 25px;
    border-bottom: solid 2px #d6c20a;
    line-height: 2;
}
.div-costbym-data>div:nth-child(1)>div:nth-child(1) , .div-costbyd-data>div:nth-child(1)>div:nth-child(1)
{
    padding-bottom: 25px;
}
.div-costbym-data>div:nth-child(1)>div:nth-child(1) svg:hover , .div-costbyd-data>div:nth-child(1)>div:nth-child(1) svg:hover , .sp-m-note-title , .sp-d-note-title
{
    color: #c00000;
}
.div-costbym-data>div:nth-child(2)>div>span:nth-child(2) , .div-costbyd-data>div:nth-child(2)>div>span:nth-child(2) , .sp-m-note-title , .sp-d-note-title
{
    font-weight: 600;
}
