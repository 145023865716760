@keyframes showSweetAlert {
    0% {
      transform: scale(0.7);
      -webkit-transform: scale(0.7); }
  
    45% {
      transform: scale(1.05);
      -webkit-transform: scale(1.05); }
  
    80% {
      transform: scale(0.95);
      -webkit-transform: scale(0.95); }
  
    100% {
      transform: scale(1);
      -webkit-transform: scale(1); 
    } 
}
.div-notification
{
    position: absolute;
    top: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.3);
    display: none;
   
}
.div-notification > div
{
    position: absolute;
    width: 45%;
    height: 30%;
    top: 25%;
    left: 27%;
    -webkit-transition: .7s ease;
    -moz-transition: .7s ease;
    -o-transition: .7s ease;
    transition: .7s ease;
    -webkit-animation: .3s showSweetAlert;
    animation: .3s showSweetAlert; 
}
.div-notification > div>div:first-child,.div-notification > div>div:last-child
{
    background-color: #4F66A9;
    font-size: 22px;
    line-height:3;
    height: 70px;
}
.div-notification .div-notification-content
{
    word-break: break-all;
    font-size: 19px;
    min-height: 128px;
    background-color: white;
    padding: 4%;
    letter-spacing: -0.2px;
}
.div-notification > div>div:last-child
{
    font-size: 18px;
    line-height: 3;
    height: 57px;
}
.div-notification > div>div:last-child>div
{
    float: right;
}
.div-notification > div>div:first-child>div
{
    padding:0 2% 0 2%;
}
.div-notification > div>div:first-child>div span
{
    margin-left: 2%;
    font-family: "Segoe UI Light";
}
.div-footer-notification div:hover
{
    background-color: #49ABD9;
}
.div-loading
{
    /*background-color: #f0f0f0;*/
    width: calc(100% - 246px);
    height: calc(100% - 60px);
    margin-top: 60px;
    left: 246px;
    position: absolute;
    top: 0;
    /*opacity: .5;*/
}
.spinner {
    position: absolute;
    top: 40%;
    left: 48%;
    width: 2.5em;
    height: 2.5em;
    border-top: 0.2em solid #698AF1;
    border-right: 0.2em solid transparent;
    animation: spinner 0.4s linear infinite;
    border-radius: 50%;
  }
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}
#wrapper {
    padding-left: calc(100% - 6px);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

#wrapper.toggled {
    padding-left: 0;
}

#sidebar-wrapper {
    z-index: 2;
    position: absolute;
    left: 250px;
    width: 200px;
    height: calc(100% - 60px);
    margin-left: -250px;
    background: #F4F5F7;
    overflow-x: hidden;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 50px;
}

#page-content-wrapper {
    position: absolute;
    width: 3px;
    height: calc(100% - 60px);
    border-right: solid 2px #E5E7E9;
}
#page-content-wrapper>div
{
    position: absolute;
    z-index: 3;
    margin: 16px 0 0 -25px;
    color: #6b778c;
    /***/
    box-shadow: 0 0 0 1px rgb(9 30 66 / 8%), 0 2px 4px 1px rgb(9 30 66 / 8%);
    color: #6b778c;
    cursor: pointer;
    height: 26px;
    opacity: 1;
    transform: translate(-50%);
    width: 26px;
    background: 0 #CEE4FF;
    border: 0 initial;
    border-color: initial;
    border-image: initial;
    border-radius: 50%;
    outline: 0;
    padding: 0 3px 0 0px;
    transition: background-color .1s linear 0s,color .1s linear 0s,opacity .3s cubic-bezier(.2,0,0,1) 0s,transform .3s cubic-bezier(.2,0,0,1) 0s;
}
#page-content-wrapper:hover
{
    border-right: solid 2px rgba(204,0,0,.2);
} 
#wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
}
#wrapper.toggled #page-content-wrapper>div
{
    margin-left: 25px;
}
.div-content-function
{
    width: calc(100% - 200px);
    margin-left: 200px;
    /*padding: 0 0 0 1.5%;*/
    overflow: auto;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.div-lst-menu-child
{
    background: #F0EEDF;
}
.div-lst-menu-child:hover
{
    background: #F0EEDF !important;
}
.div-lst-menu-active
{
    color: white;
    background:#666;
}
.div-lst-menu-active-2
{
    color: black;
    background:#BDAA8C  !important;
}
.div-lst-menu-active:hover
{
    color: #000 !important;
    background: #666 !important;
}
.div-lst-menu-active-2:hover
{
    color: #000 !important;
    background: #BDAA8C !important;
}
/*#page-content-wrapper>div:active 
{
    background-color: rgba(204,0,0,.2)!important;
    color: rgba(0,0,0,.6)!important
}*/
#page-content-wrapper>div:hover {
    background: #439aff;
    color: white;
}
#page-content-wrapper>div:hover .i-arrow 
{
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin: 1px 11px;
}
.i-arrow
{
    border: solid #6b778c;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin: 1px 11px;
}
#page-content-wrapper>div:hover .i-right
{
    margin: 1px -10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.3px;
}
.i-right
{
    margin: 1px -10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.div-content-toggle
{
    width: calc(100% - 48px) !important;
    margin-left: 48px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.div-menu-title
{
    padding: 11px 3px;
    height: 50px;
}
.div-menu-content
{
    padding: 11px 0px;
    height: calc(100% - 70px);
}

.div-DM-content
{
    width: calc(100% - 42px);
}
.div-content-menu
{
    width: 200px;
}
.div-menu-title-content p:first-child
{
    font-size: 15px;
    color: #42526E;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 3;
    white-space: nowrap;
}
.div-DM-icon
{
    width: 42px;
}
.div-menu-content>div
{
    padding: 15px 3px;
    height: 50px;
}
.div-menu-content>div:hover
{
    background : #EBECF0
}
.div-menu-content>div>div:nth-child(2)
{
    padding: 0 1px;
    font-size: 14px;
}
.div-menu-content svg
{
    font-size: 23px;
    font-weight: 700;
}
.div-menu-content-no-hv:hover
{
    background : #F4F5F7 !important
}