.div-header , .div-header > div
{
    height : 60px;
    background-color: #698AF1;
}
.div-img-logo
{
  width: 76px;
  background: url('../../../Images/LogoLV.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.div-file-content
{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.div-info-user
{
  padding-right: 1%;
  min-width: 1px;
  min-width: 270px !important;
  padding: 0 !important;
  text-transform: none !important;
}
.div-info-user img {
  border-radius: 50%;
  max-width: 43px;
}
.div-info-user-img
{
  padding: 8px;
  width: 67px;
}
.div-title-header p
{
  font-size: 16px;
  line-height: 2.4;
  margin: 5px 0 0px 25px;
}
.div-info-user-name
{
  padding: 5px 0 0 0;
  font-size: 16px;
}
.div-info-user>div:first-child
{
  padding: 5px;
}
.div-menu-logout
{
  width: 230px !important;
  height: 121px !important;
  position: absolute !important;
  background-color: #698AF1 !important;
  top: 60px !important;
  right: 0 !important;
  z-index: 2 !important;
  padding: 7px 15px 4px 15px !important;
  text-transform: none !important;
}
.div-menu-logout:hover , .div-info-user:hover
{
  color: white !important;
}
.div-menu-logout > div:first-child
{
    border-bottom: solid 1px;
    font-size: 17px;
}
.div-menu-logout > div:not(:first-child)
{
    padding: 1% 10% 0.5% 10%;
    font-size: 16px;
}
.div-logout span:last-child:hover , .div-change-p-w span:last-child:hover , .div-acc-management span:last-child:hover
{
    color: #3170C9;
}
.div-icon-menu svg
{
  font-size: 22px;
  margin-top: 14px;
}
.div-icon-menu:hover , .div-info-user:hover
{
  background-color: #487EB0;
}
.p-d-child-menu
{
  padding: 6px 15px 0 15px !important;
}
.div-header>div>div>div:nth-child(2)
{
  line-height: 1.9;
  padding-left: 10px;
  font-size: 24px;
}
.div-header>div>div:first-child
{
  padding: 5px 0;
}
.div-header>div>div:nth-child(2)>div>div
{
  width: 125px;
  font-size: 14px;
  padding: 19px 0 10px 0;
  text-transform: uppercase;
}
.div-header>div>div:nth-child(2)>div>div:hover
{
  color: #ff9866;
}