.div-xn .w-41::-webkit-scrollbar
{
    height: 0
}
.div-if-pt
{
    height: 73px;
}
.div-n-pt
{
    font-size: 15px;
    border-bottom: 2px solid firebrick;
}
.div-n-pt > span:nth-child(2)
{
    margin-left: 10px;
}
.div-n-pt > span:last-child
{
    margin-left: 3px;
}
.sp-n-pt , .sp-phone , .sp-email , .sp-nt , .sp-address
{
    font-weight: 500;
    text-transform: uppercase;
    font-size: 16px;
    opacity: .5;
}
.sp-gender
{
    padding: 0 5px;
}
.sp-gender svg
{
    font-size: 17px;
    color: #4799F3;
}
.div-if-img
{
    background-image: url("../../../../Images/user.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 83px;
}
.div-if-pt-t
{
    padding : 3px 0 0 0;
}
.div-if-pt-t-t
{
    font-size: 14px;
}
.div-if-pt-t-t > div > div:nth-child(2)
{
    padding : 1px 0 0 0;
}
.div-if-pt-t-t > div > div:first-child
{
    font-weight: 500;
    font-size: 14px;
}
.div-if-pt-t-t>div
{
    height: 22px;
}
.div-rt
{
    height: 30px;
    width: 43px;
    padding: 8px 0;
}
.div-rt svg
{
    font-size: 17px;
}
.div-rt:hover svg
{
    color: #85C4FF;
}
.div-gridview-pt-rc > div:nth-child(2)
{
    padding-left: 5px;
}
.div-pt-tt>div
{
    padding: 1%;
}
.div-pt-tt>div:nth-child(2)>div:first-child
{
    background-color: #EEEEEE;
}
.div-mc-rc
{
    font-size: 15px;
    overflow: auto;
    padding: 0 1%;
}
.div-mc-rc>div:nth-child(2)>div:nth-child(2) , .div-mc-rc>div:nth-child(6)>div:nth-child(2) , .div-mc-rc>div:nth-child(5)>div:nth-child(2) , .div-mc-rc>div:nth-child(7)>div:nth-child(2)
{
    display: flex;
}
.div-mc-rc>div:nth-child(7)>div:nth-child(2)>div:first-child , .div-mc-rc>div:nth-child(2)>div:nth-child(2)>div:first-child  , .div-mc-rc>div:nth-child(4)>div:nth-child(2)>div:first-child
{
    border-right: solid 1px rgba(127, 127, 127, 0.54);
}
.div-mc-rc>div:nth-child(3)>div:nth-child(2)>div:last-child
{
    border-left: solid 1px rgba(127, 127, 127, 0.54);
}
.div-mc-rc>div:nth-child(2)>div:nth-child(2)>div:first-child>div:nth-child(2),.div-mc-rc>div:nth-child(2)>div:nth-child(2)>div:nth-child(2)>div:nth-child(2) , .div-mc-rc>div:nth-child(6)>div:nth-child(2)>div:first-child , .div-pt-tt>div:nth-child(2)>div
{
    border-bottom: solid 1px rgba(127, 127, 127, 0.54);
}
.div-mc-rc>div:nth-child(7)>div:nth-child(2)>div:nth-child(2)>div
{
    padding: 3% 0 10%;
    border-bottom: solid 1px rgba(127, 127, 127, 0.54);
}
.div-mc-rc>div:nth-child(7)>div:nth-child(2)>div:first-child .p-d-t-b-1:not(:nth-child(1))
{
    display: flex;
    border-bottom: solid 1px rgba(127, 127, 127, 0.54);
}
.div-mc-rc>div:nth-child(7)>div:nth-child(2)>div:first-child .p-d-t-b-1:not(:nth-child(1))>div:nth-child(2)
{
    display: grid;
}
.div-mc-rc-obj
{
    height: 100px;
    overflow: auto;
}
.div-mc-rc input
{
    width: 20px;
    height: 20px;
}
.div-m-r-d
{
    height: 34px;
    padding: 3px 24px;
    border: solid 1px rgba(127, 127, 127, 0.54);
    margin: 0 1% 0 1%;
}
.div-m-r-d > span 
{
    font-size: 18px;
    text-transform: uppercase;
}
.div-mc-rc>div:not(:first-child)
{
    border: solid 1px rgba(127, 127, 127, 0.54);
    border-top:none;
}
.div-mc-rc > div:nth-child(9) , .div-mc-rc > div:nth-child(10)
{
    height: 40px;
}
.div-mc-rc>div:nth-child(3)>div:nth-child(2)>div:first-child>div
{
    padding: 1% 0 7% 0;
}
.div-mc-rc > div:nth-child(5) > div , .div-mc-rc > div:nth-child(9) > div , .div-mc-rc > div:nth-child(10) > div
{
    background: white !important;
    color: black !important;
    border-bottom: none !important;
}
.div-mc-rc > div:nth-child(5)  span , .div-mc-rc > div:nth-child(9)  span, .div-mc-rc > div:nth-child(10)  span
{
    text-transform: none !important;
}
.div-mc-rc > div:not(:first-child,:nth-child(2)) > div:first-child , .div-mc-rc > div:not(:nth-child(5)) > div:first-child
{
    padding: 0.3% 2%;
    border-bottom: solid 1px rgba(127, 127, 127, 0.54);
    background-color: #EEEEEE;
}
.div-mc-rc > div:nth-child(5) > div:first-child
{
    border-bottom : none;
}
.div-mc-rc > div:not(:first-child) > div:first-child > span
{
    font-size: 16px;
    text-transform: uppercase;
}
.div-fhir-gridview , .div-upload-file-gw
{
    padding: 0 1%;
}
.div-mc-rc > div:not(:first-child) > div:first-child > span > a , 
.div-mc-rc > div:nth-child(7) > div:nth-child(2) > div > div > div  a ,
.div-fhir-gridview a
{
    color: inherit;
    text-decoration: none;
}
.div-mc-rc > div:not(:first-child) > div:not(:first-child) > div
{
    padding: 0.5% 2% 0.5% 2%;
}
.div-xn
{
    height: 40px;
    line-height: 2.2;
}

.div-xn>div
{
    height: 100%;
}
.div-xn>div:nth-child(4)
{
    font-size: 14px;
    background-color: #EEEEEE;
    line-height: 1.3;
}
.div-xn .w-2
{
    background-color: #C1C1C1;
    color: white;
    line-height: 2.5;
}
.div-xn .w-2:hover 
{
    background-color: #A6A6A6;
}
.div-xn .w-41
{
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}
.div-xn .div-t-d-l
{
    direction: rtl;  
}
.div-xn .w-41>div
{
    font-size: 14px;
    line-height: 1.3;
    width: 130px;
    background-color: #E8F1FF;
    border-right: solid 1px white;
    display: inline-block;
}
.div-xn .w-41>div:hover
{
    background-color: #B5C7E3;
}
.div-xn>div:nth-child(3)
{
    border-right: solid 1px white;
}
.div-xn>div:nth-child(5)
{
    border-left: solid 1px white;
}
.div-xncls-title
{
    height: 48px;
    padding: 0.5% 1%;
}
.div-xncls-title>div:first-child
{
    padding-right: 0.2%;
}
.div-xncls-title select
{
    height: 30px;
    font-size: 14px;
}
.div-xncls-title select:focus
{
    box-shadow: white;
}
.div-xncls-title svg
{
    font-size: 20px;
}
.div-xncls-n-title>div:nth-child(1)
{
    line-height: 2.5;
}
.div-xncls-n-title>div:not(:nth-child(1))
{
    line-height: 1.9;
    color: white;
    font-size: 12px;
    background-color: rgba(153 , 153 , 153 , 0.64);
    margin-top: 2px;
}
.div-xncls-n-title>div:nth-child(2)
{
    border-right: solid 2px white;
    line-height: 1.9;
    color: white;
    background-color: rgba(153 , 153 , 153 , 0.64);
    margin-top: 2px;
}
.div-xncls-n-title>div:not(:nth-child(1)):hover
{
    color:#85C4FF
}
.div-xncls-compare .w-3:hover
{
    background-color: #f0f0f0;
}
.div-xncls-n-title
{
    height: 25px;
    font-size: 14px;
    line-height: 2.1;
    margin: 0.15% 1% 0 1%;
    padding: 0.1% 0 0 0;
    border-top: 1px solid #f0f0f0;
}
.div-xncls-compare 
{
    height: 32px;
    padding: 0 1%;
    line-height: 1.8;
}
.div-xncls-compare>div
{
    height: 32px;
    font-size: 19px;
    background-image: url('../../../../Images/compare.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: inherit;
}
.ul-tab-lst-pt
{
    border-bottom: solid 1px #f0f0f0;
    font-size: 14px;
    color: #000000D9;
    font-weight: 500;
    height: 35px;
    margin: 0 1%;
    padding: 6px 0;
}
.ul-tab-lst-pt>li>div
{
    padding: 2.5px 25px;
}
.ul-tab-lst-pt .nav-link.active
{
    background: none;
    color: red;
    border-bottom: solid 2px #4285F4;
    border-radius: 0;
}
#myTabContent
{
    overflow: auto;
    /*padding: 1% 0 0 0 ;*/
}