.div-content-login
{
    background: url('../../../Images/background-trong-dong.jpg');
    background-position: 270px;
    background-repeat: no-repeat;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.842) 0%,rgba(255, 255, 255, 0.829) 5%), url('../../../Images/background-trong-dong.jpg');;
}
.div-f-login-l>div
{
    padding: 14px;
    margin-top: 12%;
}
.div-f-login-l>div>div:first-child
{
    font-size: 28px;
    color: #ee4d2d;
    font-weight: 500;
    text-transform: uppercase;
}
.div-f-login-l>div>div:first-child>div:last-child
{
    padding: 33px 120px;
}
.div-login
{
    max-width: 500px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    margin: 15% 15%;
    background: transparent;
}
.div-c-ac
{
    max-width: 600px;
    height: 703px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    padding: 20px;
    margin: 89px 10px;
    background:white;
}
.div-logo-login
{
    background-color: #698AF1;
    height: 67px;
}

.div-btn-creatAccount p , .div-btn-change-pw p
{
    margin-top: 14px;
}
.div-logo-login div:first-child
{
    width: 50px;
    height: 50px;
    background-image: url("../../../Images/LogoLV.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-right: 25px;
}
.div-logo-login div:nth-child(2)
{
    line-height: 1.1;
}
.div-login>div:nth-child(4)
{
    text-align: right;
}
.div-btn-creatAccount , .div-btn-change-pw
{
    float: right;
    width: 100px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 0.8;
    background-color: #698AF1;
    margin: 53px 36px 0 0;
}
.div-btn-login 
{
    width: 100px;
    height: 40px;
    float: right;
    border-radius: 4px;
    font-size: 14px;
    padding: 8px;
    background-color: #698AF1;
    margin: 53px 36px 0 0;
}
.div-c-ac .div-btn-login , .div-btn-creatAccount , .div-btn-change-pw 
{
    margin: 53px 27px 0 0;
}
.div-create-account , .div-rt-login , .div-create-account-s-e
{
    margin: 58px 36px 0 36px;
    color: #1a73e8;
}
.div-rt-login
{
    margin: 58px 36px 0 30px;
}
.div-create-account:hover , .div-rt-login:hover
{
    background-color: #E7F2FF;
}
.div-logo-login p 
{
    margin: 0;
    font-size: 12px;
}
.div-logo-login p:first-child
{
    font-size: 24px;
}
.div-logo-login p:nth-child(1)
{
    letter-spacing: -0.5px;
    font-weight: 500;
}
.div-logo-login p:nth-child(2)
{
    font-weight: 500;
}
.div-title-login
{
    margin: 11% 0 0 0 ;
    line-height: 1.2;
}
.div-title-login-ac
{
    margin: 60px 0 37px 0px ;
    padding: 0 25px;
    line-height: 1.3;
}
.div-title-login p , .div-title-login-ac p
{
    font-size: 24px;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
}
.div-title-login p:nth-child(2) , .div-title-login-ac p:nth-child(2)
{
    font-size: 14px;
    color: #5F6368;
}
.div-login-form 
{
    margin: 30px 0 0 0;
}
.div-login-form-ac
{
    padding: 0 25px;
}
.div-login-form .holder ,.div-login-form-ac .holder{
    margin: 15px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, sans-serif;
  } 
.div-login-form-ac .holder
{
    margin: 4px 0 0 0;
    padding: 13px 0 0 0 ;
}
.div-login-form .input-holder , .div-login-form-ac .input-holder {
    position: relative;
  }
.div-login-form-ac .input-holder
{
    padding: 0 2px;
}
.div-login-form .error , .div-login-form-ac .error
{
    width: 50px;
}
.div-login-form .input , .div-login-form .error  , .div-login-form-ac .input , .div-login-form-ac .error
{
      height: 53px;
      font-size: 16px;
      padding: 13px 15px;
      border-radius: 4px;
      border: 1px solid grey;
      width: 429px;
      outline: none;
}
  .div-login-form-ac .input ,.div-login-form-ac .error
{
    width: 100%;
    height: 37px;
}
.div-login-form  .input:not(:placeholder-shown).input:not(:focus) + .placeholder {
    transform: scale(.75) translateY(-39px) translateX(-5px);
    color: grey;
  }
.div-login-form-ac  .input:not(:placeholder-shown).input:not(:focus) + .placeholder {
    transform: scale(.75) translateY(-25px) translateX(-5px);
    color: grey;
}
.div-login-form .input:focus , .div-login-form-ac .input:focus {
    border-color: #1A73E8;
  } 
.div-login-form .input:focus + .placeholder {
    transform: scale(.75) translateY(-39px) translateX(-5px);
    color: #1A73E8;
} 
 .div-login-form-ac .input:focus + .placeholder {
    transform: scale(.75) translateY(-25px) translateX(-5px);
    color: #1A73E8;
} 
.div-login-form .error , .div-login-form-ac .error {
    border-color: red;
  } 
  
.div-login-form .error + .placeholder {
    transform: scale(.75) translateY(-39px) translateX(-5px);
    color: red;
}  
.div-login-form-ac .error + .placeholder {
    transform: scale(.75) translateY(-25px) translateX(-5px);
    color: red;
}
.div-login-form .placeholder , .div-login-form-ac .placeholder {
    position: absolute;
    top: 15px;
    left: 8px;
    padding: 0 8px;
    background-color: white;
    transition: transform 150ms cubic-bezier(0.4,0,0.2,1), opacity 150ms cubic-bezier(0.4,0,0.2,1);
    color: grey;
  }
.div-login-form-ac .placeholder 
{
  font-size: 14px;
  top: 9px;
}
.input-holder div:nth-child(3)
{
    position: absolute;
    margin: -52px 0 0 377px;
    width: 52px;
    height: 52px;
    text-align: center;
    line-height: 3;
    z-index: 3;
}
.div-login-form-ac .input-holder div:nth-child(3)
{
    position: absolute;
    margin: -37px 0 0 452px;
    width: 52px;
    height: 37px;
    text-align: center;
    line-height: 2.2;
}
.input-holder i 
{
    margin: 16px 0 0 15px;
}
.div-login-form .div-err-login 
{
    margin: 0 37px;
}
.div-login-form-ac .div-err-login
{
    margin: 0 0 8px 0;
}
.div-login-form .div-err-login p , .div-login-form-ac .div-err-login p
{
    margin: 0;
    color: red;
    font-size: 14px;
}
.ip-f-name-err , .ip-l-name-err
{
    width: 50%;
    margin: -11px 0 8px 0 !important;
}
.div-email-title , .div-pw-title
{
    color: #5F6368;
    font-size: 13px;
    margin: -9px 0 0 3px;
}
.div-title-login-s-e 
{
    padding: 0 23%;
}
.div-title-login-s-e p
{
    font-size: 15px;
    border: solid 1px #dadce0;
    border-radius: 15px;
    padding: 2%;
    font-weight: 600;
    color: #3c4043;
}
.div-login-form-s-e
{
    height: 175px;
    margin: 16px 0 0 0;
    background-image: linear-gradient(to bottom,rgba(233,233,233,0) 0%,rgba(233,233,233,0) 62.22%,rgba(233,233,233,1) 40.22%,rgba(233,233,233,0) 100%);
    background-repeat: no-repeat;
    padding: 0 5%;
}
.div-login-form-s-e>div
{
    height: 100%;
    background-image: url('../../../Images/account-recovery-email-pin.gif');
    background-repeat: no-repeat;
}
.div-s-e-title
{
    text-align: left !important;
    padding: 0 40px;
}
.div-s-e-title>div:first-child
{
    font-size: 16px;
    font-weight: 600;
}
.div-login>div:nth-child(3) p
{
    color: #1a73e8;
    margin: 10px 35px 0 0 ;
}
.div-f-login-df
{
    overflow: auto;
}
.div-f-login-df::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}