.div-c-f-h-title 
{
    height: 60px;
}
.div-c-f-h-title p
{
    margin: 0;
    line-height: 1.2;
}
.div-c-f-h-title p:first-child
{
    text-transform: uppercase;
    font-size: 24px;
    color: red;
}
.div-c-f-h-title p:last-child
{
    font-size: 14px;
    font-family: "Segoe UI Light";
    color: #333;
}
.div-c-r-f-h
{
    padding: 0 1.5%;
}
.div-c-r-f-h label
{
    font-size: 13px;
}
.div-c-r-f-h > div > div
{
    padding: 0 1%;
}
.div-c-r-f-h > div:first-child > div:last-child
{
    padding: 10px 1%;
}
.div-c-r-f-h > div:first-child > div:last-child > div
{
    float: right;
    text-align: center;
    padding-top: 5px;
    width: 70px;
    height: 35px;
    border-radius: 3px;
    background-color: #698af1;
}
.div-c-r-f-h > div:last-child > div:first-child
{
    padding: 0 1%;
}
.div-c-r-f-h > div:last-child > div:first-child > div
{
    padding: 7px;
    background-color: #DFEBFF;
    border-bottom: solid 1px #bdbdbd;
}
.div-grid-a-m
{
    overflow: auto;
    margin: 0 1%;
    border: solid 1px #f0f0f0;
}
.div-grid-a-m > div
{
    border-bottom: solid 1px #f0f0f0;
    font-size: 14px;
    padding: 10px 7px;
}
.div-add-view
{
    border: solid 2px #d6d6d6;
    height: 150px;
    padding: 10px 0 ;
    margin: 0 1% 20px 1%;
    border-radius: 5px;
}
.div-add-view > div > div
{
    padding: 0 1% 2% 1%;
}
.div-add-ac-view-title
{
    padding: 10px 1%;
}
.div-add-ac-view-title span
{
    font-size: 16px;
}
.div-register-f-hos
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 40px;
    background-color: #6B8DF4;
    border-radius: 5px;
    font-size: 15px;

}
.div-register-f-hos:hover
{
    background-color: #5B76C7;
}