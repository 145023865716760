.highcharts-text-outline>tspan
{
    font-weight: 100 !important;
}
.highcharts-root
{
    font-family: "Seogoe UI" !important;
}
.div-rp-pmcy
{
    padding: 0 1%;
}
.div-rp-pmcy-title
{
    height: 90px;
}
.div-rp-pmcy-title>div:first-child
{
    line-height: 1.2;
    padding: 19px 0;
}
.div-rp-pmcy-title>div:first-child>p
{
    margin: 0;
}
.div-rp-pmcy-title>div:first-child>p:first-child
{
    text-transform: uppercase;
    font-size: 24px;
    color: red;
}
.div-rp-pmcy-title>div:first-child>p:last-child
{
    font-size: 14px;
    font-family: "Segoe UI Light";
    color: rgb(51, 51, 51);
}
.div-rp-pmcy-content>div:first-child>div:first-child>span:not(:nth-child(2))
{
    color: #666666;
    font-size: 14px;
}
.div-rp-pmcy-content>div:first-child>div:first-child>span:nth-child(2)
{
    color: #C00000;
    font-size: 15px;
}
.div-rp-pmcy-content-child
{
    overflow:auto;
    height: 100%;
}