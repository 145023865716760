.multi-range-month , .div-range-year {
    width: 100%;
    height: 24px;
    position: relative;
    margin-bottom: 2em;
  }
  .multi-range__track {
    height: 10px;
    width: 100%;
    background: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  .multi-range__track-bg {
    height: 100%;
    width: 95%;
    background: #ccc;
    position: absolute;
    top: 0;
    left: 2.5%;
  }
  .multi-range__fill {
    height: 100%;
    width: 100%;
    background: #2994b2;
    background: linear-gradient(to right, #FFD75E 0%, #C76107 100%);
    position: absolute;
    top: 0;
    left: 0;
  }
  .multi-range__handle {
    height: 16px;
    width: 5%;
    background: #343434;
    position: absolute;
    top: 50%;
    cursor: grab;
    transform: translateY(-50%);
    border-radius: 4px;
  }
  .multi-range__handle:focus:first-child {
    border: 3px solid #FFD75E;
  }
  .multi-range__handle:focus:last-child {
    border: 3px solid #C76107;
  }
  .multi-range__ticks {
    height: 100%;
    width: 95%;
    background: transparent;
    position: absolute;
    top: 0;
    left: 2.5%;
    display: flex;
    justify-content: space-between;
  }
  .multi-range__tick {
    width: 2px;
    height: 100%;
    background: #fff;
  }
  .multi-range__labels {
    font-size: 14px;
    position: absolute;
    left: 2.5%;
    min-width: 100%;
    overflow: visible;
    top: calc(68% + 1px);
  }
  .multi-range__labels > .label {
    position: absolute;
  }
  @media screen and (max-width: 709px) {
    .multi-range__labels > .label:nth-child(4n + 1) {
      display: block;
    }
  }
  @media screen and (min-width: 710px) {
    .multi-range__labels > .label:nth-child(odd) {
      display: block;
    }
  }
  @media screen and (min-width: 1560px) {
    .multi-range__labels > .label {
      display: block;
    }
  }
  .label {
    transform: translateX(-50%);
  }
.div-range-month>div:first-child , .div-range-year>div:first-child
{
    font-family: "Segoe UI Light";
    color: rgb(51, 51, 51);
    font-size: 14px;
}
.div-range-year
{
    height: 60px;
}
.dropdown-nvbd-nvbd {
  position: relative;
  font-size: 14px;
  color: #333;
}
.dropdown-nvbd .dropdown-nvbd-list 
{
  padding: 0px 2px;
  background: #fff;
  top: 0;
  left: 1.7px;
  right: 0px;
  width: calc(100% - 3px);
  box-shadow: 0 1px 2px 1px rgb(0 0 0 / 15%);
  transform-origin: 50% 0;
  transform: scale(1, 0);
  transition: transform 0.15s ease-in-out 0.15s;
  max-height: 36vh;
  overflow-y: auto;
  z-index: 3;
  position: relative;
}
.dropdown-nvbd-list-ntk
{
  top: 122px;
}
.dropdown-nvbd .dropdown-nvbd-option {
  display: block;
  height: 33px;
  padding: 4px 12px;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}
.dropdown-nvbd .dropdown-nvbd-label {
  display: block;
  height: 30px;
  background: #fff;
  border: 1px solid #eaeaea;
  padding: 6px 12px;
  line-height: 1;
  cursor: pointer;
  color: #666666;
  font-size: 14px;
  opacity: .95;
}
.dropdown-nvbd .dropdown-nvbd-label:before {
  content: '\25BD';
  float: right;
}
.dropdown-nvbd.on .dropdown-nvbd-list {
  transform: scale(1, 1);
  transition-delay: 0s;
}
.dropdown-nvbd.on .dropdown-nvbd-list .dropdown-nvbd-option {
  opacity: 1;
  transition-delay: 0.2s;
}
.dropdown-nvbd.on .dropdown-nvbd-label:before {
  content: '\25B3';
}
.dropdown-nvbd [type="checkbox"] {
  position: relative;
  top: 2px;
  margin-right: 10px;
  width: 14px;
  height: 14px;
}
.div-rp-pmcy-title>div:last-child
{
  padding: 17px 0;
}
.div-rp-pmcy-title>div:last-child span
{
  color: rgb(51, 51, 51);
  font-size: 13px;
  opacity: .9;
}
.dropdown-nvbd-option span
{
  font-size: 14px !important;
}
.dropdown-nvbd-option>div:last-child
{
  padding: 0 15px;
}
.div-sp-ntk:hover span , .div-sp-ttk:hover span
{
  color: #B1B1B1 !important;
}
.div-rp-pmcy-content
{
  overflow: auto;
  position: absolute;
  width: calc(100% - 295px);
  padding: 0 1% 0 0;
}
.div-rp-pmcy-content>div:nth-child(2)>div:first-child
{
  color: #C00000;
  font-size: 16px;
  text-transform: uppercase;
  height: 23px;
  border-bottom: none;
}
.div-rp-pmcy-content>div:nth-child(2)>div:last-child>div:first-child
{
    padding: 3px 8px;
    border-bottom: solid 1px #999999;
}
.div-rp-pmcy-content>div:nth-child(2)>div:last-child>div:first-child input
{
  height: 24px;
  border:solid 1px #D9D9D9;
}
.div-rp-pmcy-content>div:nth-child(2)>div:last-child>div:first-child input::-webkit-input-placeholder
{
  font-size: 13px;

}
.div-lb-hover-tt
{
  font-size: 13px;
}
.div-rp-pmcy-content>div>div:first-child
{
  border-bottom: solid 1px #D5D2D2;
}
.div-lb-hover-tt:hover , .div-lb-ttk-m:hover 
{
  background: #C8C8C8;
}
.highcharts-pie-series .highcharts-data-label-connector {
	stroke: silver;
	stroke-width: 2px;
}
.div-null-data
{
  width: 100%;
  height: 100%;
  background-image: url('../../../Images/cancel-cloud-storage\ \(1\).png');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.div-chartPtKCB-content
{
  padding: 12% 0;
}
.div-chartPtAge-content>div , .div-chartPtKCB-content>div
{
  padding: 7% 1%;
  line-height: 1.2;
}
.div-chartPtAge-content>div p , .div-chartPtKCB-content>div p
{
  margin-bottom: 0;
}
.div-chartPtAge-content>div p:first-child , .div-chartPtKCB-content>div p:first-child
{
  font-size: 25px;
  padding: 0;
  color: #FF2F01;
}
.div-chartPtAge-content>div:nth-child(2) p:first-child , .div-chartPtKCB-content>div:nth-child(2) p:first-child
{
  color:#FF8A00;
}
.div-chartPtAge-content>div:nth-child(3) p:first-child , .div-chartPtKCB-content>div:nth-child(3) p:first-child
{
  color:#0376CF;
}
.div-chartPtAge-content>div:nth-child(4) p:first-child 
{
  color:#EAC558;
}
.div-chartPtAge-content>div p:last-child , .div-chartPtKCB-content>div p:last-child
{
  font-size: 15px;
}
.highcharts-data-labels text
{
  font-weight: 200 !important;
  color: #333 !important;
}
.ip-cb-ntk[type=checkbox]:checked , .ip-cb-cskcb[type=checkbox]:checked , .ip-cb-ttk[type=checkbox]:checked , .ip-cb-ttk[type=radio]:checked, .ip-cb-ngtk[type=checkbox]:checked
{
  background-color: #666666;
}
.ip-cb-ntk[type="checkbox"]:checked:after, .ip-cb-cskcb[type="checkbox"]:checked:after, .ip-cb-ttk[type="checkbox"]:checked:after , .ip-cb-ttk[type="radio"]:checked:after  , .ip-cb-ngtk[type=checkbox]:checked::after
{
  display: block;
}
.ip-cb-ntk[type="checkbox"]:after , .ip-cb-cskcb[type="checkbox"]:after , .ip-cb-ttk[type="checkbox"]:after , .ip-cb-ttk[type="radio"]:after , .ip-cb-ngtk[type=checkbox]::after {
  content: '';
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #666666;
  display: none;
}
.ip-cb-ttk[type=radio]
{
  margin-top: 6px;
  border: 1px solid #666666;
  padding: 6px;
  border-radius: 13%;
  -webkit-appearance: none;
}
.ip-cb-ttk[type=radio]:disabled
{
  opacity: .2;
}
.div-box-search
{
  padding: 8px 10px 3px 10px
}
.div-box-search input
{
  font-size: 14px;
  color: rgb(51, 51, 51);
  border: solid 1px #bdbdbd;
}
.div-box-search input:focus
{
  outline: none;
}
.dropdown-nvbd-list-cskcb
{
  overflow: hidden !important;
}
.div-lst-cskcb
{
  overflow: auto ;
  max-height: 24vh;
}
.highcharts-container 
{
  margin: 0px auto;
  display: table;
}