#slide{
	height: 480px;
	}
	
* {
	box-sizing: border-box
}

.mySlides {
	display: none
}
.mySlides img{
    object-fit: cover;
    min-height: 50px;
}
.slideshow-container {
	width: 100%;
	position: relative;

}
/* Next & previous buttons */
.prevBanner, .nextBanner {
    cursor: pointer;
    position: absolute;
    top: 160px;
    width: auto;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 30px;
    transition: 0.6s ease;
    opacity: .6;
}
.div-bdn
{
    position: absolute;
    top: 384px;
    left: 16%;
    background-color: #698af1;
    border-color: #698af1;
    width: 140px;
    height: 39px;
    border-radius: 22px;
    line-height: 2.4;
    font-size: 16px;
    text-transform: uppercase;
}
.prevBanner {
	left: 6%;
}
/* Position the "next button" to the right */
.nextBanner {
	right: 6%;
	border-radius: 3px 0 0 3px;
}
/* On hover, add a black background color with a little bit see-through */
.prevBanner:hover, .nextBanner:hover {
	opacity: 1;
    background-color: rgba(0,0,0,0.5);
}
/* Caption text */
.text {
	color: #f2f2f2;
	font-size: 15px;
	padding: 8px 12px;
	position: absolute;
	bottom: 8px;
	width: 100%;
	text-align: center;
}

.div-introduce
{
    padding: 10px 50px;
}
.div-content
{
    overflow: auto;
}
.div-content::-webkit-scrollbar {
    width: 0px;
}
.div-introduce>div:first-child
{
    font-size: 28px;
    text-transform: uppercase;
    font-weight: bold;
    color: #3749af;
    padding: 25px 0;
}
.div-introduce>div:nth-child(2)
{
    font-size: 18px;
    color: #777;
    padding: 0 0 65px 0;
}
.div-introduce>div:nth-child(3)
{
    
}
.div-introduce>div:nth-child(3)>div:first-child
{
    padding: 5% 3%;
    border-right: dashed 2px #dbdade;
}
.div-introduce>div:nth-child(3)>div:first-child img:not(:first-child)
{
    position: relative;
}
.div-introduce>div:nth-child(3)>div:first-child img:nth-child(2)
{
    top: -69%;
    left: 105.1%;
}
.div-introduce>div:nth-child(3)>div:first-child img:nth-child(3)
{
    top: -50%;
    left: 95.6%;
}
.div-introduce>div:nth-child(3)>div:first-child img:nth-child(4)
{
    top: -45%;
    left: 86.2%;
}
.div-introduce>div:nth-child(3)>div:nth-child(2)>div:first-child
{
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
}
.div-introduce>div:nth-child(3)>div:nth-child(2)>div
{
    padding: 0 0 40px 10%;
}
.div-introduce>div:nth-child(3)>div:nth-child(2)>div ul
{
    font-size: 18px;
}
.div-introduce>div:nth-child(3)>div:nth-child(2)>div>div
{
    height: 4px;
    background-image: linear-gradient(to right, #8252fa 0%, #eca2f1 100%)
}
.div-benefit
{
    background-image: url('../../../Images/bg_chude.jpg');
}
.div-benefit>div:first-child
{
    font-size: 26px;
    color: #3948af;
    text-transform: uppercase;
    font-weight: 500;
    padding: 30px 0;
}
.div-benefit>div:nth-child(2)>div>div:nth-child(2)
{
    padding: 8px 0;
}
.div-benefit>div:nth-child(2)>div>div:first-child
{
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.div-benefit>div:nth-child(2)>div:first-child>div:first-child
{
    background-image: url('../../../Images/quan_ly_ho_so.png');
}
.div-benefit>div:nth-child(2)>div:nth-child(2)>div:first-child
{
    background-image: url('../../../Images/co_cau_to_chuc.png');
}
.div-benefit>div:nth-child(2)>div:nth-child(3)>div:first-child
{
    background-image: url('../../../Images/quy_trinh_thanh_toan.png');
}
.div-benefit>div:nth-child(2)>div:nth-child(4)>div:first-child
{
    background-image: url('../../../Images/xu_ly_cong_viec.png');
}
.div-to-top
{
    position: absolute;
    background: #3762EF;
    bottom: 0;
    right: 0;
    margin: 0 30px 35px 0;
    padding: 2px 15px;
    font-size: 25px;
    border-radius: 4px;
    display: none;
}
.div-to-top:hover{
    background: #333;
}
.row
{
    --bs-gutter-x:0 !important
}